import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from "@types";

const logo1 = require("../../../../assets/logo.png");
const logo2 = require("../../../../assets/logo3.png");

const Logo: Icon = ({ colorMode = "light" }) => {
  return (
    <LogoContainer>
      <img src={colorMode === "light" ? logo1 : logo2} width="150" />
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  font-size: 22px;
  .info {
    margin-left: 20px;
    font-family: "Montserrat", sans-serif;
    .subtitle {
      font-size: 16px;
      opacity: 0.6;
    }
  }
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
